import React from 'react'
import { Box, Typography, Grid, withStyles, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { HiDotsVertical } from "react-icons/hi";
const useStyles = makeStyles((theme) => ({
  mainBox: {
    position: "relative",
    padding: '14px',
    background: "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
    backdropFilter: "blur(42px)",
    borderRadius: '10px',
    '& a': {
      fontSize: '20px',
      color: '#353840',
      marginBottom: '20px',
      textDecoration: "none",
    },
    "& p": {
      fontSize: "18px",
      marginTop: "20px",
      color: "#fff ",
      lineHeight: "14px",
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      }
    },
  },
  imgBox: {
    borderRadius: '10px',
    width: '100%',
    overflow: 'hidden',
    transition: '0.5s',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '140px',
    margin: 0,
    '&:hover': {
      '& img': {
        transform: ' scale(1.1)',
      },
    },
    '& img': {
      width: 'auto',
      height: 'auto',
      maxHeight: '100%',
      borderRadius: '10px',
      transition: '0.5s',
      maxWidth: '100%',
    },
  },
  userBox: {
    marginTop: "-25px",
    "& .userImg": {
      "& img": {
        objectFit: " none",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        border: "1px solid #81A5C7",
        marginLeft: "-45px",
        position: "relative",
        transition: '0.5s',
        "&:hover": {
          zIndex: "2",
          transform: ' scale(1.1)',
        },
      },
    },
    "& button": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: "#fff",
      color: "#000",
      minWidth: "30px",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
  },
  textBox: {
    textAlign: "left",
    marginTop: "10px",
    "& h5": {
      fontSize: "14px",
      fontWeight: 600,
      marginBottom: "5px",
      color: " #fff",
    },
    "& h6": {
      fontSize: "12px",
      fontWeight: 400,
      color: " #fff",
    },
    "& h3": {
      fontSize: "16px",
      fontWeight: 600,
      color: " #fff",
    },
  },
  placeBtn: {
    marginTop: "15px",
    "& button": {
      height: "35px",
      width: "100%",
      backgroundColor: "#fff",
      borderRadius: "5px",
      fontSize: "12px",
      lineHeight: "14px",
      textTransform: "capitalize",
      fontWeight: "700",
      "&:hover":{
        backgroundColor: "#313131",
        color:"#fff",
      },
    },
  },

}))
export default function Release(props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data } = props
  return (
    <Box className={classes.mainBox} align="center">
      <Box style={{ textDecoration: "none" }}>
        <Box className={classes.imgBox} mb={4} mt={2}>
          <img src="images/card-img/1.png" />
        </Box>
        <Box className={classes.userBox}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box className="userImg">
                <img src="images/users/1.png" alt="usericon" />
                <img src="images/users/2.png" alt="usericon" />
                <img src="images/users/3.png" alt="usericon" />
                <img src="images/users/4.png" alt="usericon" />
              </Box>
            </Grid>
            <Grid item xs={4} align="right">
              <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{ padding: 0 }}>
                  <HiDotsVertical />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Report</MenuItem>
                  <MenuItem onClick={handleClose}>Block</MenuItem>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.textBox}>
          <Typography variant="h5">Abstract Art 04</Typography>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography variant="h6">Last Bid : 1.445 AVAX</Typography>
            </Grid>
            <Grid item xs={5} align="right">
              <Typography variant="h3">$4,568.36</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.placeBtn}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button> Place a bid</Button>
            </Grid>
            <Grid item xs={6} align="right">
              <Button>History</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>

  )
}