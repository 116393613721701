import React, { createContext, useEffect, useState } from 'react';
import { injected, SUPPORTED_WALLETS } from '../connectors';
import { useWeb3React } from '@web3-react/core';
import {
  ACTIVE_NETWORK,
  Finalcontractaddress,
  NetworkDetails,
} from '../constants/';
import ThetaPunkABI from '../abis/ThetaPunkABI.json';
import axios from 'axios';
import { getWeb3ContractObject, getWeb3Obj, getContract } from '../utils';
import { toast } from 'react-toastify';

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem('userAddress', userAddress);
  } else {
    sessionStorage.removeItem('userAddress');
  }
};

export default function AuthProvider(props) {
  const { activate, account, library, chainId } = useWeb3React();
  const [nftList, setNftList] = useState([]);
  const [userTotalNFTS, setUserTotalNFTS] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [isSaleActive, setIsSaleActive] = useState(false);
  const [adminWalletAddress, setAdminWalletAddress] = useState('');
  const [currentTrancheCount, setCurrentTrancheCount] = useState(0);
  const [tranchDetails, setTranchDetails] = useState();
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log('ERROR', error);
      toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: NetworkDetails,
      });
    } catch (error) {
      console.log('ERROR', error);
      toast.warn(error.message);
    }
  };

  const connectWalletWeb3 = (data) => {
    const connector = data.connector;

    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }

    activate(connector, undefined, true).catch((error) => {
      if (error) {
        toast.error(JSON.stringify(error.message));
        activate(connector);
      }
    });
  };

  let data = {
    nftList,
    userTotalNFTS,
    maxSupply,
    totalSupply,
    isSaleActive,
    adminWalletAddress,
    currentTrancheCount,
    tranchDetails,
    getGalleryData: () => Gallery(),
    getMoreData: () => getMoreData(),
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectWalletWeb3(data),
  };

  const Gallery = async () => {
    setNftList([]);
    const contract = getContract(
      Finalcontractaddress,
      ThetaPunkABI,
      library,
      account
    );
    const txaCount = await contract.balanceOf(account);
    const txa = txaCount.toString();
    console.log('txa', txa);
    setUserTotalNFTS(txa);

    try {
      for (let i = 0; i < txa; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());
        const res = await axios.get(filter);

        if (res.status === 200) {
          setNftList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (e) {
      console.log('ERROR', e);
    }
  };
  React.useEffect(() => {
    if (account && chainId === ACTIVE_NETWORK) {
      Gallery();
    }
  }, [account, chainId]);

  React.useEffect(() => {
    getMoreData();
    getTranchDetails();
  }, []);

  const getMoreData = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contract = await getWeb3ContractObject(
        ThetaPunkABI,
        Finalcontractaddress
      );
      const adminAccount = await contract.methods.owner().call();
      setAdminWalletAddress(adminAccount);

      const getLeftTokensForCurrentTranche = await contract.methods
        .getLeftTokensForCurrentTranche()
        .call();
      console.log(
        'getLeftTokensForCurrentTranche',
        getLeftTokensForCurrentTranche
      );
      setCurrentTrancheCount(getLeftTokensForCurrentTranche);

      const saleIsactive = await contract.methods.saleIsActive().call();
      console.log('saleIsactive', saleIsactive);
      setIsSaleActive(saleIsactive);
      console.log('contract', contract.methods);
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  const getTranchDetails = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contract = await getWeb3ContractObject(
        ThetaPunkABI,
        Finalcontractaddress
      );
      const totalSupp = await contract.methods.totalSupply().call();
      const totalSupply = parseFloat(totalSupp.toString());
      setTotalSupply(totalSupply);
      const MAX_PUNKS = await contract.methods.MAX_NFT_SUPPLY().call();
      setMaxSupply(parseFloat(MAX_PUNKS.toString()));
      const getNFTTranches = await contract.methods.getNFTTranches().call();
      const getTranchePrice = await contract.methods.getTranchePrice().call();
      let tranchData = [];
      for (let i = 0; i < Object.keys(getNFTTranches).length; i++) {
        const lastCount = i == 0 ? 0 : Number(getNFTTranches[i - 1]);
        const nftCount = Number(getNFTTranches[i] - 1);
        const leftNfts =
          totalSupply >= lastCount &&
          totalSupply <= nftCount &&
          nftCount - totalSupply > 0
            ? nftCount - totalSupply
            : totalSupply >= nftCount
            ? 0
            : nftCount - lastCount + 1;

        let obj = {
          leftNfts: leftNfts,
          lastCount: lastCount,
          nftCount: nftCount,
          nftPrice: Number(web3.utils.fromWei(getTranchePrice[i])),
          percentage: Math.floor(
            (Number(getNFTTranches[i]) / Number(MAX_PUNKS)) * 100
          ),
          lastPercentage:
            i == 0
              ? 0
              : Math.floor(
                  (Number(getNFTTranches[i - 1]) / Number(MAX_PUNKS)) * 100
                ),
        };
        console.log('obj', obj);

        tranchData.push(obj);
      }
      console.log('tranchData', tranchData);

      setTranchDetails(tranchData);
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  // useEffect(() => {
  //   const userAddress = window.sessionStorage.getItem('userAddress');
  //   if (userAddress) {
  //     data.connectWallet();
  //   }
  // }, []); //eslint-disable-line

  useEffect(() => {
    if (window.sessionStorage.getItem('walletName')) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem('walletName')
      );
      connectWalletWeb3(selectectWalletDetails[0].data);
    }
  }, []);

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
