import React, { useState, useContext, useEffect } from "react";
import Slider from "react-slick";
import {
  makeStyles,
  Box,
  Button,
  Grid,
  FormControl,
  IconButton,
  Container,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTheme } from "@material-ui/styles";
import { GrFormAdd } from "react-icons/gr";
import { BiMinus } from "react-icons/bi";
import ButtonCircularProgress from "../../components/Loaders/ButtonCircularProgress";
import { UserContext } from "../../context/User";
import { useWeb3React } from "@web3-react/core";
import { getContract, getWeb3ContractObject, getWeb3Obj } from "../../utils";
import {
  ACTIVE_NETWORK,
  Finalcontractaddress,
  maxMintLimit,
} from "../../constants";
import ThetaPunkABI from "../../abis/ThetaPunkABI.json";
import { toast } from "react-toastify";
import ConnectWallet from "../../components/ConnectWalletPopUp/Index";
import Artwork from "./Artwork";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  baanerBox: {
    overflow: "hidden",
    padding: "80px 0px",
    "& label": {
      fontSize: "18px",
      color: "#FFFFFF",
      marginBottom: "10px",
    },
  },
  hlderframe: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    marginTop: "-160px",
  },
  slideFrame: {
    borderRadius: "15px 15px 0 0",
    overflow: "hidden",
  },
  marketplaceBoxHolder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "700px",
    position: "relative",
    overflow: "hidden",
    minWidth: "1665px",
    [theme.breakpoints.down("sm")]: {
      height: "600px",
      marginTop: "-60px",
    },
    "&::after": {
      content: "''",
      // background: "rgb(0,0,0)",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "200px",
      background:
        "linear-gradient( 0deg, rgba(0,0,0,1) 53%, rgba(0,0,0,0) 96%)",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
      },
    },
  },
  marketplaceBox: {
    width: "230px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      top: "120px",
    },

    "& figure": {
      width: "100%",
      // height: '290px',
      marginBottom: "5px",
      marginTop: "0px",
      marginLeft: "0px",
      borderRadius: "15px",
      overflow: "hidden",
      "& img": {
        width: "100%",
        // height: '100%',
      },
    },
  },

  quantity: {
    display: "flex",
    "& button": {
      width: "50px",
      height: "50px",
      background: "#222222",
      border: "none",
      color: "#868383",
      fontSize: "30px",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "center",
    },
    "& input": {
      width: "50px",
      height: "50px",
      background: "#000",
      border: "1px solid #595959 !important",
      color: "#868383",
      fontSize: "20px",
      margin: "0 10px",
      textAlign: "center",
      "&:hover": {
        border: "1px solid #595959 !important",
      },
    },
  },
  imagesBox: {
    position: "relative",
  },
  shade: {
    position: "absolute",
    left: "auto",
    top: "auto",
    right: "10%",

    transform: "rotate(45deg)",
    zIndex: " -1",
    width: " 300px",
    height: "300px",
    borderRadius: " 1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgb(39 121 124) 95.78%)",
    opacity: "0.55",
    // -webkit-filter: blur(100px);
    filter: "blur(100px)",
  },
  shade3: {
    position: "absolute",
    left: "20%",
    right: "auto",
    bottom: "auto",
    transform: "rotate(45deg)",
    zIndex: " -1",
    width: " 250px",
    height: "250px",
    borderRadius: " 1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, #1b38db75 0%, #536dfe9e 95.78%)",
    opacity: "0.55",
    // -webkit-filter: blur(100px);
    filter: "blur(100px)",
  },

  inputbutton: {
    color: "#ffffff",
    fontSize: "27px",
    minWidth: "59px",
    boxSizing: "border-box",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontWeight: "500",
    lineHeight: "1.75",
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    background: "#222222",
    margin: "5px",
    border: "0px transparent",
    "&:hover": {
      border: "1px solid rgb(89, 89, 89) !important",
    },
  },
  slider1: {
    width: "100%",
    margin: "0 auto ",
    maxWidth: "50%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      marginTop: "50px",
    },
    "& h6": {
      color: "#FFFFFF",
      fontSize: "18px",
      marginBottom: "10px",
    },
  },
}));
export default function Dashboard(props) {
  var classes = useStyles();
  const settings = {
    dots: false,
    arrows: false,
    vertical: true,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    cssEase: "ease-in-out",
    slidesToScroll: 1,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          centerPadding: "50px",
          autoplay: true,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerPadding: "40px",
          autoplay: true,
        },
      },
    ],
  };
  const [openWalletConnect, setOpenWalletConnect] = useState(false);
  const user = useContext(UserContext);
  const { account, library, chainId } = useWeb3React();
  const [numberofnft, setNumberofnft] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [buyPrice, setBuyPrice] = React.useState(0);
  const [isPriceLoading, setIsPriceLoading] = React.useState(false);
  useEffect(() => {
    if (account) {
      setOpenWalletConnect(false);
    }
  }, [account]);

  const mintNFT = async () => {
    if (account && numberofnft && numberofnft !== "") {
      setIsLoading(true);

      try {
        const web3 = await getWeb3Obj();
        var balance = web3.eth.getBalance(account);
        var walletBalance = web3.utils.fromWei((await balance).toString());
        if (parseFloat(walletBalance) > parseFloat(buyPrice)) {
          const contract = getContract(
            Finalcontractaddress,
            ThetaPunkABI,
            library,
            account
          );

          const checkIsActive = await contract.saleIsActive();
          console.log("checkIsActive", checkIsActive);
          console.log("contract", contract);

          if (checkIsActive) {
            const NFTP = await contract.getNFTPrice(numberofnft);
            const tx = await contract.mintNFT(numberofnft, {
              value: NFTP.toString(),
              from: account,
            });
            await tx.wait();
            toast.success("Successfully minted");
            setIsLoading(false);
            user.getGalleryData();
            user.getMoreData();
          } else {
            setIsLoading(false);
            toast.warn("Sale is not active");
          }
        } else {
          toast.warn("Insufficient funds");
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("ERRROR", error);
        toast.error(error.message);
      }
    }
  };

  const updateNFTPrice = async (cancelTokenSource) => {
    try {
      setIsPriceLoading(true);
      const web3 = await getWeb3Obj();
      const contract = await getWeb3ContractObject(
        ThetaPunkABI,
        Finalcontractaddress
      );
      const NFTP = await contract.methods.getNFTPrice(numberofnft).call();
      const etherValue = await web3.utils.fromWei(NFTP.toString());
      setBuyPrice(etherValue);
      setIsPriceLoading(false);
    } catch (error) {
      setIsPriceLoading(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (numberofnft > 0) {
      updateNFTPrice(cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [numberofnft]);

  return (
    <Box className={classes.baanerBox}>
      <Artwork user={user} />

      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}
      <div className={classes.shade}></div>
      <div className={classes.shade3}></div>
      <Container maxWidth='md'>
        <Box
          className={classes.slider1}
          mb={2}
          style={{ zIndex: "99", position: "relative" }}
        >
          <Box mb={3}>
            <Typography variant='h6'>Quantity</Typography>

            <Button
              className={classes.inputbutton}
              onClick={() => {
                if (numberofnft > 1) {
                  setNumberofnft(numberofnft - 1);
                }
              }}
            >
              -
            </Button>
            <Button
              className={classes.inputbutton}
              style={{ border: "1px solid #595959" }}
            >
              <span>{numberofnft}</span>
            </Button>
            <Button
              className={classes.inputbutton}
              onClick={() => {
                if (numberofnft < maxMintLimit) {
                  setNumberofnft(numberofnft + 1);
                }
              }}
            >
              +
            </Button>
          </Box>
          <Box mb={2}>
            <label>Price</label>
            <FormControl
              fullWidth
              className={clsx(classes.margin, classes.textField)}
              variant='outlined'
            >
              <OutlinedInput
                placeholder={buyPrice}
                id='outlined-adornment-weight'
                inputProps={{
                  readOnly: true,
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    {isPriceLoading && <ButtonCircularProgress />}
                    {` AVAX`}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <Box align='center' mt={5}>
            <Button
              variant='contained'
              size='large'
              color='primary'
              onClick={() => {
                if (account) {
                  mintNFT();
                } else {
                  setOpenWalletConnect(true);
                }
              }}
              disabled={isLoading && chainId !== ACTIVE_NETWORK}
            >
              {account ? "Mint" : "CONNECT WALLET"}{" "}
              {isLoading && <ButtonCircularProgress />}
            </Button>
          </Box>
        </Box>
      </Container>
      <Box className={classes.hlderframe}>
        <Box className={classes.marketplaceBoxHolder}>
          <Box
            className={`${classes.marketplaceBox} wow bounceInUp`}
            data-wow-delay='0s'
          >
            <Box
              className={classes.slideFrame}
              style={{ marginTop: "0px", width: "100%" }}
            >
              <Slider {...{ ...settings, autoplaySpeed: 5000 }}>
                <figure>
                  <img src='images/nfts/1.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/2.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/3.jpg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/4.jpeg' alt='' />
                </figure>
              </Slider>
            </Box>
          </Box>
          <Box
            className={`${classes.marketplaceBox} wow bounceInUp`}
            data-wow-delay='0.2s'
          >
            <Box
              className={classes.slideFrame}
              style={{ marginTop: "180px", width: "100%" }}
            >
              <Slider {...{ ...settings, autoplaySpeed: 8000 }}>
                <figure>
                  <img src='images/nfts/5.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/6.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/7.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/8.jpeg' alt='' />
                </figure>
              </Slider>
            </Box>
          </Box>
          <Box
            className={`${classes.marketplaceBox} wow bounceInUp`}
            data-wow-delay='0.4s'
          >
            <Box
              className={classes.slideFrame}
              style={{ marginTop: "260px", width: "100%" }}
            >
              <Slider {...{ ...settings, autoplaySpeed: 9000 }}>
                <figure>
                  <img src='images/nfts/9.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/10.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/11.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/12.jpeg' alt='' />
                </figure>
              </Slider>
            </Box>
          </Box>
          <Box
            className={`${classes.marketplaceBox} wow bounceInUp`}
            data-wow-delay='0.6s'
          >
            <Box
              className={classes.slideFrame}
              style={{ marginTop: "350px", width: "100%" }}
            >
              <Slider {...{ ...settings, autoplaySpeed: 2000 }}>
                <figure>
                  <img src='images/nfts/13.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/14.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/15.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/1.jpeg' alt='' />
                </figure>
              </Slider>
            </Box>
          </Box>
          <Box
            className={`${classes.marketplaceBox} wow bounceInUp`}
            data-wow-delay='0.8s'
          >
            <Box
              className={classes.slideFrame}
              style={{ marginTop: "260px", width: "100%" }}
            >
              <Slider {...{ ...settings, autoplaySpeed: 5000 }}>
                <figure>
                  <img src='images/nfts/2.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/3.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/4.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/5.jpeg' alt='' />
                </figure>
              </Slider>
            </Box>
          </Box>
          <Box
            className={`${classes.marketplaceBox} wow bounceInUp`}
            data-wow-delay='1s'
          >
            <Box
              className={classes.slideFrame}
              style={{ marginTop: "180px", width: "100%" }}
            >
              <Slider {...{ ...settings, autoplaySpeed: 8000 }}>
                <figure>
                  <img src='images/nfts/6.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/7.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/8.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/9.jpeg' alt='' />
                </figure>
              </Slider>
            </Box>
          </Box>
          <Box
            className={`${classes.marketplaceBox} wow bounceInUp`}
            data-wow-delay='1.2s'
          >
            <Box
              className={classes.slideFrame}
              style={{ marginTop: "0px", width: "100%" }}
            >
              <Slider {...{ ...settings, autoplaySpeed: 2000 }}>
                <figure>
                  <img src='images/nfts/10.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/12.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/11.jpeg' alt='' />
                </figure>
                <figure>
                  <img src='images/nfts/13.jpeg' alt='' />
                </figure>
              </Slider>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
