export const NetworkContextName = "Avalanche Network";
export const ACTIVE_NETWORK = 43114;
export const Finalcontractaddress =
  "0xe8665B64823079C7D05ffd87126a3d2f491EC3Eb";
export const RPC_URL = "https://api.avax.network/ext/bc/C/rpc";
export const maxMintLimit = 10;
export const NetworkDetails = [
  {
    chainId: "0xA86A",
    chainName: "Avalanche Network",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"],
  },
];
