import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  AppBar,
  appBarCloses,
  Toolbar,
  IconButton,
  Grid,
  Box,
  Hidden,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreAction from './MoreAction';
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
// styles
import useStyles from './styles';
// components
import { Button } from '../Wrappers';
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { BiDotsVertical } from 'react-icons/bi';
import { useUserDispatch, signOut } from '../../context/UserContext';
import { UserContext } from '../../context/User';
import { useWeb3React } from '@web3-react/core';
import MenuLink from './MenuLink';
import ConnectWallet from '../ConnectWalletPopUp/Index';
export default function Header(props) {
  var classes = useStyles();
  const [openWalletConnect, setOpenWalletConnect] = useState(false);

  const user = useContext(UserContext);
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { active, account, chainId, library } = useWeb3React();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const moreRef = useRef(null);

  useEffect(() => {
    if (account) {
      setOpenWalletConnect(false);
    }
  }, [account]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar
      position='fixed'
      className={layoutState.isSidebarOpened ? `appBar` : `appBarCloses`}
      elevation={0}
      style={{ backgroundColor: '#000 !important' }}
    >
      <Grid container alignItems='center'>
        <Grid item xs={5} sm={2}>
          <Toolbar className={classes.toolbar}>
            <Hidden mdUp>
              <IconButton
                color='inherit'
                onClick={() => toggleSidebar(layoutDispatch)}
                className={classNames(
                  classes.headerMenuButtonSandwich,
                  classes.headerMenuButtonCollapse
                )}
              >
                {layoutState.isSidebarOpened ? (
                  <MenuIcon
                    classes={{
                      root: classNames(
                        classes.headerIcon,
                        classes.headerIconCollapse
                      ),
                    }}
                  />
                ) : (
                  <MenuIcon
                    classes={{
                      root: classNames(
                        classes.headerIcon,
                        classes.headerIconCollapse
                      ),
                    }}
                  />
                )}
              </IconButton>
            </Hidden>
            <Hidden smUp>
              <Link to='/'>
                {' '}
                <img src='../images/logo.svg' style={{ width: '120px' }} />
              </Link>
            </Hidden>
            {/* <Hidden xsDown>
              <Link to="/">
                {" "}
                <img src="../images/logo.svg" />
              </Link>
            </Hidden> */}

            <div className={classes.grow} />
          </Toolbar>
        </Grid>
        <Grid item xs={7} sm={10} align='right' className={classes.sidbar}>
          <Box className={classes.sidbar2}>
            <Hidden smDown>
              <MenuLink />
            </Hidden>
            <Hidden mdUp>
              <MoreAction />
            </Hidden>
            {account ? (
              <Link className={classes.navLinks} to='/app/wallet'>
                Wallet
              </Link>
            ) : (
              <button
                className={classes.buttonright}
                variant='contained'
                size='large'
                onClick={() => setOpenWalletConnect(true)}
                style={{ color: '#fff !important' }}
              >
                CONNECT
              </button>
            )}
          </Box>
        </Grid>
      </Grid>
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}{' '}
    </AppBar>
  );
}
