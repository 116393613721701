import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  baanerBox: {
    position: "relative",
    paddingBottom: "0px",
    paddingTop: "80px",
  },
  textbox: {
    position: "relative",
    textAlign: "center",
    zIndex: "2",
    "& button": {
      "&:last-child": {
        marginLeft: "20px",
      },
    },
    "& h1": {
      fontSize: "55px",
      fontWeight: "700",
      lineHeight: "70px",
      display: "inline-block",
      background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      [theme.breakpoints.down("lg")]: {
        fontSize: "45px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        lineHeight: "40px",
      },
    },
    "& p": {
      fontSize: "18px",
      marginTop: "20px",
      color: "#fff ",
      lineHeight: "24px",
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
  },

  Nftimg: {
    position: "relative",
    zIndex: "2",
    textAlign: "right",
    "& img": {
      width: "100%",
      maxWidth: "80%",
      margin: "0 auto",
    },
  },
  bgimg: {
    position: "absolute",
    zIndex: "-1",
    top: "50%",
    transform: " translateY(-50%)",
    left: "-150px",
  },
  shade3: {
    position: "absolute",
    left: "40%",
    right: "auto",
    bottom: "auto",
    transform: "rotate(45deg)",
    zIndex: " -1",
    width: " 550px",
    height: "550px",
    borderRadius: " 1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135 / 55%) 0%, rgb(39 121 124 / 60%) 95.78%)",
    opacity: "0.55",
    // -webkit-filter: blur(100px);
    filter: "blur(100px)",
    [theme.breakpoints.down("xs")]: {
      right: "-80px",
    },
  },
}));
