import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  aboutsection: {
    padding: "0px 0px 25px",
    position: "relative",
    textAlign: "center",
  },
  textbox: {
    "& h1": {
      fontSize: "45px",
      fontWeight: "600",
      marginBottom: "20px",
      align: "center",
      background:
        "-webkit-linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
    "& p": {
      fontSize: "16px",
      marginTop: "20px",
      color: "#484c50",
    },
  },
  note: {
    color: "#484c50",
    fontStyle: "italic",
  },
  typographyETH: {
    color: "#fff",
    fontSize: "12px",
    textAlign: "center",
  },
  diabox: {
    height: "5px",
    backgroundColor: "#e91e63",
    background: "linear-gradient(#2599fa, #42E8E0)",
    borderLeft: "4px solid #000",
  },
  typographydia: {
    overflow: "hidden",
    flexWrap: "nowrap",
    fontSize: "11px",
    color: "#fff",
    textAlign: "center",
  },
  filterbox: {
    flesWarp: "warp",
    minWidth: "90px",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 13%) 0px 5px 15px",
    border: "1px solid #ccc",
    padding: "10px",
    borderRadius: "10px",
    margin: "10px 5px",
    transition: " 02s",
    "&:hover": {
      transform: "translateY(-10px)",
    },
    "& label": {
      fontSize: "15px",
      paddingBottom: 0,
      color: "#35076a",
    },
    "& h5": {
      fontSize: "35px",
      color: "rgb(48, 7, 96)",
      [theme.breakpoints.down("xs")]: {
        fontSize: "26px",
      },
    },
  },
}));

export default function BestSeller({ user }) {
  const classes = useStyles();

  return (
    <Box className={classes.aboutsection}>
      <Container maxWidth='lg'>
        {/* <Box className={classes.textbox}>
          <Typography variant='h1'>
            DIGITAL &nbsp;
            <span style={{ color: "#300760" }}>ARTWORK</span>
          </Typography>
        </Box> */}
        <Box
          style={{
            minWidth: "100%",
            overflowX: "auto",
          }}
          mt={2}
          mb={6}
        >
          <Grid
            container
            align='left'
            direction='row'
            xs={12}
            style={{
              flexWrap: "nowrap",
              minWidth: "900px",
              Width: "900px",
            }}
          >
            {user?.tranchDetails &&
              user.tranchDetails.map((data, i) => {
                return (
                  <Grid
                    item
                    container
                    direction='column'
                    style={{
                      width: `${25}%`,
                    }}
                    key={i}
                  >
                    <Typography className={classes.typographydia}>
                      {data.lastCount} - {data.nftCount} NFTs (
                      {i == 0 ? data.leftNfts + 1 : data.leftNfts} left)
                    </Typography>
                    <Box style={{ width: "100%" }} className={classes.diabox} />
                    <Typography className={classes.typographyETH}>
                      {data.nftPrice} AVAX
                    </Typography>
                  </Grid>
                );
              })}
            {/* <Grid item container direction='column' style={{ width: "12%" }}>
              <Typography className={classes.typographydia}>
                0 - 3999 NFTs
              </Typography>
              <Box style={{ width: "100%" }} className={classes.diabox} />
              <Typography className={classes.typographyETH}>1 AVAX</Typography>
            </Grid> */}

            {/* <Grid item container direction='column' style={{ width: "13%" }}>
              <Typography className={classes.typographydia} style={{}}>
                4000 - 7999 NFT's
              </Typography>

              <Box style={{ width: "100%" }} className={classes.diabox} />
              <Typography className={classes.typographyETH}>3 AVAX</Typography>
            </Grid> */}
            {/*    <Grid item container direction='column' style={{ width: "13%" }}>
              <Typography className={classes.typographydia}>
                {" "}
                8000 - 11999 NFTs
              </Typography>

              <Box style={{ width: "100%" }} className={classes.diabox} />
              <Typography className={classes.typographyETH}>5 AVAX</Typography>
            </Grid>
            <Grid item container direction='column' style={{ width: "13%" }}>
              <Typography className={classes.typographydia}>
                {" "}
                12000 - 15999 NFTs
              </Typography>

              <Box style={{ width: "100%" }} className={classes.diabox} />
              <Typography className={classes.typographyETH}>9 AVAX</Typography>
            </Grid>
            <Grid item container direction='column' style={{ width: "12%" }}>
              <Typography className={classes.typographydia}>
                {" "}
                16000 - 19999 NFTs
              </Typography>

              <Box style={{ width: "100%" }} className={classes.diabox} />
              <Typography className={classes.typographyETH}>11 AVAX</Typography>
            </Grid>

            <Grid item container direction='column' style={{ width: "12%" }}>
              <Typography className={classes.typographydia}>
                20000 - 21999 NFT's
              </Typography>

              <Box style={{ width: "100%" }} className={classes.diabox} />
              <Typography className={classes.typographyETH}>15 AVAX</Typography>
            </Grid>
            <Grid item container direction='column' style={{ width: "12%" }}>
              <Typography className={classes.typographydia}>
                22000 - 22250 NFTs
              </Typography>

              <Box style={{ width: "100%" }} className={classes.diabox} />
              <Typography className={classes.typographyETH}>30 AVAX</Typography>
            </Grid>
            <Grid item container direction='column' style={{ width: "13%" }}>
              <Typography className={classes.typographydia}>
                22251 - 22256 NFTs
              </Typography>

              <Box style={{ width: "100%" }} className={classes.diabox} />
              <Typography className={classes.typographyETH}>
                100 AVAX
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
