import React from "react";
import { Box } from "@material-ui/core";
import Mint from "../dashboard/Mint";

export default function MintNFT() {
  return (
    <Box>
      <Mint />
    </Box>
  );
}
