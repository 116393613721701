import React, { useState, useContext } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
// styles
import useStyles from "./styles";
// components
import { Button } from "../Wrappers";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { UserContext } from "../../context/User";
import { useWeb3React } from "@web3-react/core";

export default function Header(props) {
  var classes = useStyles();
  const user = useContext(UserContext);
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { active, account, chainId, library } = useWeb3React();

  return (
    <Box className={classes.mainmenus}>
      <Link className={classes.navLinks} to="/app/dashboard">
        Home
      </Link>
      <Link className={classes.navLinks} to="/app/attributes">
        Attributes
      </Link>
      <Link className={classes.navLinks} to="/app/mint">
        Mint
      </Link>
      <Typography variant="body2" component="span" className={classes.navLinks}>
        <img src="images/audited.svg" />
      </Typography>
      {/* <Typography variant='body2' component='span' className={classes.navLinks}>
        DADDY $20
      </Typography> */}
    </Box>
  );
}
