import React, { useState } from 'react';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import useStyles from './styles';
import PageTitle from '../../components/PageTitle/PageTitle';
import PublicMint from './PublicMint';
// import HowWorks from "./HowWorks";
import InvestmentCard from '../../components/InvestmentCard';
import { useHistory } from 'react-router';
import Mint from './Mint';
import Artwork from './Artwork';

const TeamCard = [
  {
    propertyName: ' Property #1',
    price: '$245',
    rating: '80% ',
  },
  {
    propertyName: ' Property #1',
    price: '$245',
    rating: '80% ',
  },
];
export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();
  return (
    <>
      <Box className={classes.baanerBox}>
        <div className={classes.shade3}></div>

        <Box className={classes.textbox}>
          {/* <img src='../images/banner-bg.png' className={classes.bgimg} /> */}
          <Typography variant='h1'>Dex DADDY Decentralized Mint NFT</Typography>
          <Typography variant='body1'>
            User-Driven, Decentralized NFT Minting{' '}
          </Typography>
          <Box mt={4}>
            <Button
              onClick={() => {
                history.push('/app/mint');
              }}
              variant='contained'
              size='large'
              color='secondary'
            >
              MINT
            </Button>
            {/* <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.buttonright}
                  onClick={() =>
                    window.open(
                      "https://medium.com/theta-network/theta-blockhain-now-accessible-through-metamask-plug-in-61b278633264"
                    )
                  }
                >
                  DEMO
                </Button> */}
          </Box>
        </Box>

        {/* <Grid
            item={12}
            md={6}
            lg={6}
            textAlign="center"
            align="center"
            style={{ width: "100%", pointerEvents: "none" }}
          >
            <Box className="cardbox" mt={7}>
              <Box className="card1 card">
                <InvestmentCard />
              </Box>
              <Box className="card2  card">
                <InvestmentCard />
              </Box>
              <Box className="card3 card">
                <InvestmentCard />
              </Box>
            </Box>
          </Grid> */}
      </Box>
      <PublicMint />
      <Mint />
      {/* <Artwork/> */}
      {/* <HowWorks /> */}
    </>
  );
}
