import React from 'react';
import {
  Grid,
  Box,
  Link,
  Button,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineGooglePlus } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: 'relative',
    paddingTop: '57px',

    '& p': {
      fontSize: '12px',
      color: '#fff',
      margin: '0',
      padding: '13px 0 10px',
      borderTop: '1px solid #252525',
      '& a': {
        fontSize: '12px',
        color: '#fff',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <Box className={classes.footerSection}>
      <p align='center'>
        {new Date().getFullYear()}@{' '}
        <a href='https://dexdaddy.com/' target='_blank'>
          DexDaddy.
        </a>{' '}
        All Right reserved
      </p>
    </Box>
  );
}
