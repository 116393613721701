import React from "react";

export default function Attributes() {
  return (
    <div style={{ paddingBottom: "150px" }}>
      {" "}
      <img
        style={{ width: "100%" }}
        src='https://dexdaddyupdatedpublicdocuments.s3.ap-southeast-1.amazonaws.com/G-NFT-Attributes.png'
        alt=''
      />
    </div>
  );
}
