import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import {
  Box,
  IconButton,
  Link,
  Grid,
  makeStyles,
  Button,
  TextField,
  Typography as MaterilTypography,
} from "@material-ui/core";
import Footer from "./Footer";
import Icon from "@mdi/react";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";

// styles

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useWeb3React } from "@web3-react/core";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import UserSearchNFT from "../../pages/UserSearchNFT/";
import NFTDetails from "../../pages/NFTDetails/";
import MyWallet from "../../pages/Wallet/Index";
import MintNFT from "../../pages/MintNFT/";
import Attributes from "../../pages/Attributes/";
// import Notifications from "../../pages/notifications";
// import Maps from "../../pages/maps";
// import Tables from "../../pages/tables";
// import Test from "../../pages/test";
// import Icons from "../../pages/icons";
// import Charts from "../../pages/charts";
// context
import { useLayoutState } from "../../context/LayoutContext";
import { ACTIVE_NETWORK, NetworkContextName } from "../../constants";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: "24px 150px",
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
    paddingTop: "80px",
    [theme.breakpoints.down("md")]: {
      padding: "24px 24px",
      paddingTop: "80px",
    },
  },
  contentShift: {
    width: `calc(100vw - 240px)`,
    position: "absolute",
    right: "0",
    overflowX: "hidden",
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
    },
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },

  footerSection: {
    "& h6": {
      margin: 0,
      fontWeight: "600",
      fontSize: "18px",
      color: "#42E8E0",
      marginBottom: "20px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    "& ul": {
      listStyle: "none",
      paddingLeft: "0",
      margin: "0",
      "& li": {
        marginBottom: "8px",

        "& a": {
          fontSize: "14px",
          color: "#848484",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
          },
          "&:hover": {
            textDecoration: "none",
            color: "#42E8E0",
          },
        },
      },
    },
  },
  buttonright: {
    background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
    fontSize: "14px ",
    border: "1px solid transparent",
    fontWeight: 600,
    height: "44px ",
    color: "#FFFFFF",
    minWidth: "135px ",
    borderRadius: "5px",
    boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      color: "#fff",
      transition: "0.5s",
      boxSizing: " border-box",
      border: " 2px solid transparent",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
    },
  },
}));
function Layout(props) {
  const classes = useStyles();
  // global
  var layoutState = useLayoutState();
  const { account, active, library, chainId } = useWeb3React();
  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          {/* <div className={classes.fakeToolbar} /> */}
          <Switch>
            <Route path='/app/dashboard' component={Dashboard} />
            {/* <Route path='/app/search-NFT' component={UserSearchNFT} /> */}
            <Route path='/app/NFT-details' component={NFTDetails} />
            <Route path='/app/wallet' component={MyWallet} />
            <Route path='/app/mint' component={MintNFT} />
            <Route path='/app/typography' component={Dashboard} />
            <Route path='/app/attributes' component={Attributes} />

            {/* <Route path="/app/tables" component={Tables} /> */}
            {/* <Route path="/app/test" component={Test} /> */}
            {/* <Route path="/app/notifications" component={Notifications} /> */}
            <Route
              exact
              path='/app/ui'
              render={() => <Redirect to='/app/ui/icons' />}
            />
            {/* <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} /> */}
          </Switch>

          <Footer />
        </div>
      </>
      {account && (
        <Dialog
          open={account && chainId !== ACTIVE_NETWORK}
          minWidth='md'
          fullWidth
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {"Netwok Change Error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Box ml={3} pb={3}>
                <MaterilTypography style={{ color: "#fff" }}>
                  Please switch to {NetworkContextName}
                </MaterilTypography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default withRouter(Layout);
