import React, { useContext, useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import ThetaPunkABI from '../../abis/ThetaPunkABI.json';
import {
  ACTIVE_NETWORK,
  Finalcontractaddress,
  NetworkContextName,
  RPC_URL,
} from '../../constants/';
import { getWeb3ContractObject, getWeb3Obj, getContract } from '../../utils/';
import { useLocation, useHistory } from 'react-router';
import axios from 'axios';
import DataLoading from '../../components/Loaders/DataLoading';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import ButtonCircularProgress from '../../components/Loaders/ButtonCircularProgress';
import { UserContext } from '../../context/User';

const useStyles = makeStyles((theme) => ({
  NewBreed: {
    background: '#000',
    color: 'white',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(18),
    },
  },
  attBox: {
    border: '1px solid #FFB72C',
    padding: 5,
    textAlign: 'center',
    borderRadius: 5,
    color: 'white',
  },
}));

export default function MarketDetail() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { account, library, chainId } = useWeb3React();
  const [transferAddress, setTransferAddress] = useState('');
  const [nftDetails, setnftDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [isUpdate, setIsUpdate] = useState(false);
  const [ownerOf, setOwnerOf] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getDetails = async (id) => {
    setIsLoading(true);
    try {
      const contract = await getWeb3ContractObject(
        ThetaPunkABI,
        Finalcontractaddress
      );

      const ownerOf_L = await contract.methods.ownerOf(id.toString()).call();
      setOwnerOf(ownerOf_L);

      const filter = await contract.methods.tokenURI(id.toString()).call();
      const res = await axios.get(filter);

      if (res.status === 200) {
        setnftDetails({ id: id.toString(), nfdData: res.data });
        console.log(' res.data', res.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('ERROR', error);
      setIsLoading(false);
    }
  };

  const transferFromHandler = async () => {
    if (chainId === ACTIVE_NETWORK) {
      if (transferAddress && transferAddress !== '') {
        const web3 = await getWeb3Obj();
        const dataRes = web3.utils.isAddress(transferAddress);
        if (dataRes) {
          try {
            setIsUpdate(true);
            const contract = getContract(
              Finalcontractaddress,
              ThetaPunkABI,
              library,
              account
            );

            const res = await contract.transferFrom(
              account,
              transferAddress,
              nftDetails.id
            );
            await res.wait();
            toast.success('Success');
            setIsUpdate(false);
            getDetails(nftDetails.id);
            user.getGalleryData();
            user.getMoreData();

            handleClose();
          } catch (error) {
            setIsUpdate(false);
            toast.error(error.message);
            console.log('error', error);
          }
        } else {
          toast.error('Please enter valid address');
        }
      } else {
        toast.error('Please enter address');
      }
    } else {
      toast.warn(`Please switch to ${NetworkContextName}`);
    }
  };

  useEffect(() => {
    if (location.search && location.search.slice(1, location.search.length)) {
      let id = location.search.slice(1, location.search.length);
      getDetails(id);
    }
  }, [location]);

  return (
    <>
      {isLoading ? (
        <DataLoading />
      ) : (
        <Box className={classes.NewBreed} maxWidth='md' display='flex'>
          {nftDetails ? (
            <Container>
              <Box mb={5}>
                <Container maxWidth='lg'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant='h2' style={{ color: '#fafafa' }}>
                          Details
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Box>

              <Grid container spacing={2} style={{ display: 'flex' }}>
                <Grid item xs={12} xl={6} md={6} sm={12}>
                  <Box>
                    <img
                      style={{ width: 550, maxWidth: '100%', borderRadius: 10 }}
                      src={nftDetails.nfdData.image}
                      alt=' '
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} xl={6} md={6}>
                  <Box>
                    <Box>
                      <Typography
                        gutterBottom
                        variant='h3'
                        style={{ color: '#fff' }}
                      >
                        {nftDetails.nfdData.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant='h4'
                        style={{ color: '#fff' }}
                      >
                        {nftDetails.id}
                      </Typography>
                    </Box>

                    <Typography
                      gutterBottom
                      variant='body1'
                      style={{ marginTop: 30 }}
                    >
                      {nftDetails.nfdData.description}
                    </Typography>
                    <Grid
                      container
                      spacing={1}
                      style={{ display: 'flex', marginTop: 20 }}
                    >
                      {nftDetails &&
                        nftDetails?.nfdData?.attributes?.map((data) => {
                          return (
                            <Grid item xl={3} sm={3} md={3}>
                              <Box className={classes.attBox}>
                                <Typography variant='subtitle1'>
                                  {data.trait_type}
                                </Typography>
                                <Typography variant='body2'>
                                  {data.value}
                                </Typography>
                              </Box>
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Box mt={3} textAlign='right'>
                      {!account && (
                        <Button
                          style={{
                            backgroundColor: 'rgb(16 86 84)',
                            margin: 5,
                          }}
                          variant='contained'
                          color='secondary'
                          onClick={() => user.connectWallet()}
                        >
                          Connect Wallet
                        </Button>
                      )}
                      {ownerOf &&
                        account &&
                        account.toLowerCase() === ownerOf.toLowerCase() && (
                          <Button
                            style={{
                              backgroundColor: 'rgb(16 86 84)',
                              margin: 5,
                            }}
                            variant='contained'
                            color='secondary'
                            onClick={handleClickOpen}
                          >
                            Transfer
                          </Button>
                        )}
                      <Button
                        style={{
                          backgroundColor: 'rgb(16 86 84)',
                          margin: 5,
                        }}
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth
                maxWidth='sm'
                disableBackdropClick={isUpdate}
                disableEscapeKeyDown={isUpdate}
              >
                <DialogContent style={{ backgroundColor: '#000000a1' }}>
                  <Box p={3}>
                    <label style={{ color: '#fff' }}>User Wallet ID</label>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      fullWidth
                      value={transferAddress}
                      inputProps={{
                        readOnly: isUpdate,
                      }}
                      onChange={(e) => setTransferAddress(e.target.value)}
                    />
                  </Box>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color='primary'
                      style={{ color: '#fff' }}
                      disabled={isUpdate}
                    >
                      Cancel
                    </Button>
                    <Button
                      color='primary'
                      style={{ color: '#00FFAB' }}
                      autoFocus
                      disabled={isUpdate}
                      onClick={() => transferFromHandler()}
                    >
                      Transfer {isUpdate && <ButtonCircularProgress />}
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>
            </Container>
          ) : (
            <Box>
              <NoDataFound />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
